.wrapper {
  width: calc(100% - 30px);
  margin: 15px;
}

.historiesWrapper {
  /* width: 90%; */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.historyCompWrapper {
  width: 100%;
  margin-bottom: 25px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateTimeWrapper {
  width: 11%;
}

.dateTimeWrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;
  color: #4d4d4d;
}

.queryWrapper {
  min-height: 74px;
  max-height: auto;
  width: calc(100% - 11%);
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameAndQueryWrapper p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #365069;
}

.query {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.headerWrapper {
  height: 56px;
  width: 100%;

  background-color: #365069;
}

.header {
  width: 98%;
  height: 100%;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageWrapper {
  height: 100%;
}

.logoWrapper {
  width: 150px;

  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.logoName {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: "#365069";

  margin-left: 5px;
}

.profileWrapper {
  width: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profileName p {
  padding: 8px;
  color: #d7dce1;
}

.profileIcon {
  width: 24px;
  height: 24px;
  text-align: center;

  border-radius: 50%;
  background-color: #d7dce1;
}

.dropdownIcon p {
  color: #d7dce1;
}
