.divWrapper {
    height: 54px;
    background-color: #E4EAFA;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 24px;
}

.paddingStyle {
    padding: 12px;
}

.selectAllBtnWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px;
}

.heightStyle {
    height: 200px;
}



