.tabFooterWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
}

.buttonsWrapper {
  height: 100%;
  width: 95%;
  margin: auto;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hideBtn {
  display: none;
}

.nextBtn {
  position: absolute;
  right: 0;
  padding: 10px;
}
