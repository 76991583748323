.imgWrapper {
    width: 20%;
    height: 100%;
    padding: 20px 10px 0px;
}

.imgStyle {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.mainHeading {
    width: 95%;
    height: auto;
    margin: 30px ;
    background-color: white;
    border: 1px solid #d7dce1;
    border-radius: 8px;
}

.headingWrapper {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    height: 52px;
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top-right-radius: 8px;
    background-color: #ffffff;
}

.paddingStyle {
    padding: 10px;
}

.btnWrapper {
    width: 100%;
    height: 64px;
    padding: 10px;
    background:  #ffffff;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}


/* ApproverDocUpdDealist.jsx......             */

.main {
    padding: 16px;
    justify-content: space-between;
    align-items: space-between;
    display: flex;
}