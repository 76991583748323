/* styles.css */

.custom-dropdown {
    position: relative;
    width: 149px;
    margin: 0 auto;
  }
  
  .dropdown-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f2f2f2;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  
  .dropdown-select:focus {
    outline: none;
    border-color: #007bff; /* Highlight border color on focus */
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  