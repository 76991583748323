.shrinkSidebarWrapper {
    width: 70px;
    height: calc(100vh - 56px);
    position: relative;
  
    background-color: #ffffff;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.05);
    transition: width 0.2s ease-in-out;
  }
  
  .expandSidebarWrapper {
    width: 264px;
    height: calc(100vh - 56px);
    position: relative;
  
    background-color: #ffffff;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.05);
    /* transition: width 0.5s ease-in-out; */
  }
  
  .shrinkBottomWrapper {
    width: 100%;
    position: absolute;
    bottom: 10px;
  
    border-top: 1px solid #ebeef0;
  }
  
  .expandBottomWrapper {
    width: 50px;
    position: absolute;
    bottom: 10px;
  
    border-top: 1px solid #ebeef0;
  }
  
  .shrinkBottomButtonWrapper {
    width: 100%;
    padding-top: 10px;
    text-align: center;
  
    cursor: pointer;
  }
  
  .expandBottomButtonWrapper {
    width: 50px;
    padding-top: 10px;
    text-align: center;
  
    cursor: pointer;
  }
  
  .tabsListWrapper {
  }
  
  .activeTab {
    background-color: #fef0ec;
    text-decoration: none;
    border-left: 4px solid #f58667;
  }
  
  .tab {
    color: #365069;
    text-decoration: none;
  }
  