.headerWrapper {
  height: 56px;
  width: 100%;

  background-color: #365069;
}

.header {
  width: 98%;
  height: 100%;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageWrapper {
  height: 100%;
}

.logoWrapper {
  width: 150px;

  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.logoName {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ebeef0;

  margin-left: 5px;
}

.profileWrapper {
 
width: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profileName p {
  padding: 8px;
  color: #d7dce1;
}

.profileIcon {
  width: 24px;
  height: 24px;
  text-align: center;

  border-radius: 50%;
  background-color: #d7dce1;
}

.dropdownIcon p {
  color: d7dce1;
 
}
