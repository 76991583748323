.App {
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  .container {
    display: flex;
  }
  main {
    width: 100%;
    padding: 20px;
  }
  .sidebar {
    background: #000;
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
  }
  .top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
  }
  .logo {
    font-size: 30px;
  }
  .bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
  }
  .link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
  }
  .link:hover {
    background: lightskyblue;
    color: #000;
    transition: all 0.5s;
  }
  .active {
    background: lightskyblue;
    color: #000;
  }
  .icon,
  .link_text {
    font-size: 20px;
  }
  
 .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background: #365069 !important;
    color: #fff !important;
    border-radius: 50%;
  }
  .css-169iwlq-MuiCalendarPicker-root {
    background: #ebeef0 !important;
    border-radius: 0px 0px 10px 10px;
    color: #365069;
  }
  
  .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
    border-radius: 0px;
    background-color: transparent;
  }
  


   