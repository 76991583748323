* {
  margin: 0px;
  padding: 0px;
 
  box-sizing: border-box;
}

.left {
  width: 40%;
  min-height: 100%;
  border-right: 1px solid #d7dce1;
 
}

.right {
  width: 70%;
  position: relative;
  background-color: white;
  border-radius: 0px 8px 8px 0px;
}

.right p {
  font-weight: 700;
font-size: 14px;
line-height: 20px;
}


.tab {
  width: 100%;
  height: 52px;
  padding: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center
}

.tab p{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.tabActive {
  width: 100%;
  height: 52px;
  background-color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-left: 4px solid #f58667;
  border-radius: 8px 0px 0px 0px;
}

.tabActive p{
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.tabsWrapper {
  display: flex;

  width: 95%;

  

  height: calc(100% - 44px);
  
  margin: 20px auto;
  background-color: #e6e6e6;
  
  border: 1px solid #d7dce1;
  border-radius: 8px;
}
