.wrapper {
  width: calc(100% - 30px);
  margin: 15px;
  padding-right: 118px;
}

.historiesWrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.historyLoader {
  justify-content: center;
  align-items: center;
  display: flex;
}

.historyTotalPage {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  margin: auto;
  height: 65px;
}
