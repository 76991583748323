.loader {
    justify-content: center;
    align-items: center;
    display: flex;
}

.filterListWrapper {
    padding: 16px;
    justify-content: space-between;
    align-items: space-between;
    display: flex;
    
}