.documentHeaderWraper {
  background-color: #e4eafa;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 24px;
}
.monthEndUploadImageDiv {
  width: 20%;
  height: 100%;
  padding: 20px 10px 0px;
}
.monthEndUploadImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.uploadReportStateLoader {
  justify-content: center;
  align-items: center;
  padding: 80px;
  display: flex;
}
.viewDealsWrapper {
  padding: 10px;
  padding-top: 30px;
}
.dealNameStyle {
  color: #afb9c3;
  font-weight: 400;
  font-size: 16px;
}
.noContentDiv {
  padding-top: 30px;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
}
.viewDetailsMainButtonDiv {
  padding-top: 30px;
  bottom: 0;
  width: 100%;
  padding-right: 10px;
  height: 74px;
  background: #ffffff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
.CommanTableStatusContainerDiv {
  padding: 2px 8px;
  border-radius: 4px;
}
.CommanTableStatusContainerP {
  font-size: 12px;
  font-weight: 800;
}
.MonthEndUploadListDiv {
  width: 20%;
  height: 100%;
  padding: 20px 10px 0px;
}
.MonthEndUploadListImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.ViewSOAReportDetailsDiv {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}
