.wrapper {
    height: 70px;
    padding: 5px;
    border: 1px dashed #8DDDA8;
   
    background: #E3F6E9;
    box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16),
      0px 0px 1px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .filenameWrapper {
    width: 70%;
    padding: 5px;
    color: #365069;
    display: flex;
    size: 14px;
    font-weight: 400;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
  
  .filenameWrapper p {
    margin-left: 10px;
  }

  .break {
    flex-basis: 100%;
    height: 0;
    align-items: center;
    margin: auto;
    justify-content: center;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #8696A5;
  }

  .filesizeWrapper p {
    font-size: 12px;
    align-items: center;
    margin: auto;
    justify-content: center;
    display: flex;
    text-align: center;
  }

  .singleIconWrapper {
    height: 100%;
    
    border-radius: 8px;
    /* border: 1px solid black; */
    display: flex;
    padding: 8px;
    /* align-items: center; */
  }
  
