.wrapper {
  width: calc(100% - 30px);
  margin: 15px;
  /* padding-right: 118px; */
}

.queriesWrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
