.wrapper {
  height: 52px;
  padding: 5px;

  background: #ffffff;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

typewriter {
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

.filenameWrapper {
  width: 70%;
  padding: 5px;
  color: #4d4d4d;
  display: flex;
  size: 14px;
  font-weight: 400;
  align-items: center;
}

.filenameWrapper p {
  margin-left: 10px;
}

.iconsWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 10px;
  width: 10%;
}

.iconsSmallWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 18%;
}

.singleIconWrapper {
  height: 100%;

  border-radius: 8px;
  border: 1px solid black;
  display: flex;
  padding: 8px;
  align-items: center;
}
