.imgWrapper {
    width: 20%;
    height: 100%;
    padding: 20px 10px 0px;
  }
  
  .imgStyle {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  
  .paddingTop {
    padding-top: 14px;
  }
  .ProfileDetailsTabDiv {
    padding-top: 10px;
  }
  .ProfileDetailsTabFormControl {
    padding-left: 10px;
  }
  
  .leReportWrapper {
    background-color: #e4eafa;
    height: 54px;
    align-items: center;
    display: flex;
    padding-left: 14px;
  }
  
  .paddingStyle {
    padding: 10px;
  }
  
  .btnWrapper {
    width: 100%;
    justify-content: flex-end;
    align-content: flex-end;
    padding-right: 20px;
    padding-top: 20px;
    display: flex;
    height: 60px;
    padding-bottom: 10px;
    background: #ffffff;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .documentHeaderProfileDetailsTab {
    background-color: #e4eafa;
    height: 54px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 24px;
  }
  
  .ProfileDetailsTabMainDiv {
    width: 95%;
    height: auto;
    margin: 30px;
    background-color: white;
    border: 1px solid #d7dce1;
    border-radius: 8px;
  }
  
  .ProfileDetailsTabUploadsDocuments {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    height: 52px;
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top-right-radius: 8px;
    background-color: #ffffff;
  }
  .ProfileDetailsTabSubmitDocuments {
    width: 100%;
    height: 64px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
  }

  .filterListWrapper {
    padding: 16px;
    justify-content: space-between;
    align-items: space-between;
    display: flex;
}

  /* L2ViewOriginatorsDetails.jsx    */

  .padding {
    padding: 14px;
  }
  
  .mappingStyle {
    padding: 5px;
    width: auto;
  }

  .halfPadding {
    padding: 5px;
  }

  .statusTopDiv {
    justify-content: space-between;
    align-items: space-between;
    display: flex;
  }

  .statusWidth{
    width: 368px;
  } 

