.viewThreadWrapper {
  display: flex;
}

.viewThread {
  width: calc(100% - 11%);
  background: #ffffff;
  /* Light/Shadow/Large */

  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  height: 53px;
}

.viewThread {
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewThreadText {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Colors/Piramal Orange/PO-100 */

  color: #f26841;
}
