.statusPTag {
    font-size: 12px;
    font-weight: 800;
}

.noRecordFoind {
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}