.main {
  height: 100%;
  width: 100%;
}

.topDiv{
  margin-left: auto;
  width: 35%;
  margin-top: 20px;
}

.wrapper {
  width: calc(100% - 30px);
  margin: 15px;
}

.historiesWrapper {
  /* width: 90%; */
  margin: auto;

  display: flex;
  flex-direction: column;
}

.notFound {
  padding: 50px;
  justify-content: center;
  align-content: center;
  height: 100%;
  display: flex;
  width: 100%;
}