.tabStyle {
  height: calc(100% - 172px);
}

.layoutMainImageDiv {
  width: 20%;
  height: 100%;
  padding: 20px 10px 0px;
}

.layoutMainImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
