.GenerateReportDocumentHeaderDiv {
  background-color: #e4eafa;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 24px;
}
.GenerateReportPlaceholder {
  color: #afb9c3;
}
.GenerateReportImageDiv {
  width: 20%;
  height: 100%;
  padding: 20px 10px 0px;
}
.GenerateReportImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.GenerateReportStateLoader {
  justify-content: center;
  align-items: center;
  padding: 80px;
  display: flex;
}
.GenerateReportWrapper {
  padding: 10px;
  padding-top: 30px;
}
.dealNameStyle {
  color: #afb9c3;
  font-weight: 400;
  font-size: 16px;
}
.viewDetailsMainButtonDiv {
  padding-top: 30px;
  bottom: 0;
  width: 100%;
  padding-right: 10px;
  height: 74px;
  background: #ffffff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
.CommanPadding {
  padding: 10px;
}
.CommanPaddingTop {
  padding-top: 3px;
}

.noContentDiv {
    padding-top: 30px;
    bottom: 0;
    width: 100%;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  }