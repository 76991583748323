.tabHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  height: 52px;
  border-bottom: 1px solid lightgray;
  border-top-right-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}
