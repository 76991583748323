.historyCompWrapper {
  width: 100%;
  margin-bottom: 25px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateTimeWrapper {
  width: 11%;
}

.dateTimeWrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;
  color: #4d4d4d;
}

.queryWrapper {
  min-height: 74px;
  max-height: auto;
  width: calc(100% - 11%);
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameAndQueryWrapper p {
  font-weight: 700;
  font-size: 14px;
  color: #365069;
  line-height: 20px;
}

.name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.query {
 
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
