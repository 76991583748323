.QueryCompWrapper {
  width: 100%;
  margin-bottom: 25px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateTimeWrapper {
  width: 11%;
}

.dateTimeWrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;
  color: #4d4d4d;
}

.queryWrapper {
  min-height: 74px;
  max-height: auto;
  width: calc(100% - 11%);
  padding: 15px;
  background: #ffffff;

  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameAndQueryWrapper {
  width: 90%;
 
}

.nameAndQueryWrapper p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  
}

.nameAndQueryWrapper .query {
  font-weight: 400;
  font-size: 14px;

  line-height: 20px;
  
}

.name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.query {
  /* font-family: "Nunito"; */
  /* font-style: normal; */
  /* font-weight: 400; */
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.inputWrapper {
  position: relative;
}

.input {
  width: 100%;
  border: 1px solid #365069;
  border-radius: 8px;
  padding: 10px 10px;
  min-height: 91px;
  max-height: 100%;
  resize: none;
  position: relative;
}

.postButton {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px 12px;
}
