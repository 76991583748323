.breadcrumbWrapper {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;

  position: sticky;
  width: 100%;
  height: 44px;
  top: 0;
  z-index: 100;
}
