.filtersCompWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 35px auto;
  /* margin-bottom: ; */
}

.filtersWrapper {
  width: calc(100% - 25%);
  height: 100%;
  display: flex;
  align-items: center;
}

.filterByWrapper {
  width: 11%;
  text-align: center;
}

.filterBy {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Colors/Neutral/N-100 */

  color: #000000;
}

.componentWrapper {
  width: 50%;
  margin-left: 5px;
}

 .width {
  width: 90%;
 }

 