.main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    margin: auto;
    height: 65px;
    box-shadow:0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
    border-radius: 0px 0px 8px 8px;
}

.pTag {
    color: #5E7387;
    font-size: 14;
    font-weight: 450;
    padding-top: 10px;
    padding-left: 24px;
}