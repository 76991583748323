.uploadMonthlyCollectionReportSummaryMainBox {
  padding: 10px;
}

.monthlyCollectionFile {
  padding: 5px;
}
.tabFooterDiv {
  height: "64px";
}
.documentHeaderWraper {
  background-color: #e4eafa;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 24px;
}
.noContentDiv {
  padding: 16px;
  display: "flex";
  justify-content: "space-between";
  align-items: "space-between";
}
.f1ListOfDealImg {
  display: "flex";
  justify-content: "center";
  align-items: "center";
}
.filterListWrapper {
  padding: 16px;
  justify-content: space-between;
  align-items: space-between;
  display: flex;
}