.wrapper {
    height: 70px;
    width: 100%;
    border-radius: 12px;
    border: 1px dashed #8ddda8;
    background-color: #e3f6e9;
    position: relative;
  
    display: flex;
    align-items: center;
  }
  
  .iconWrapper {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    right: 15px;
    cursor: pointer;
  }
  
  .DetailsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .DetailsWrapper p {
    
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #365069;
  }
  
  .together {
    display: flex;
  }

  .padding {
    padding: 10px;
  }
  