.paddingTopStyle {
    padding-top: 14px;
}

.btnWrapper {
    width: 100%;
    justify-content: flex-end;
    align-content : flex-end;
    padding-right: 20px;
    padding-top: 20px;
    display: flex;
    height: 60px;
    padding-bottom: 10px;
    background:  #ffffff;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
}

/* Documents.jsx */

.descWrappaer {
    background-color: #E4EAFA;
    height: 54px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 14px;
}

.uploadDiv {
    padding-top: 10px;
}

/* ListOfDeals.jsxx */

.filterListWrapper {
    padding: 16px;
    justify-content: space-between;
    align-items: space-between;
    display: flex;
}

.loader {
    justify-content: center;
    align-items: center;
    display: flex;
}

/* CreateDeal.jsx          */

.DealWrapper {
    height: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* OriginatorDealList.jsx... */

.main {
    padding: 16px;
    justify-content: space-between;
    align-items: space-between;
    display: flex;
}