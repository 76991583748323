/* AssignSPOCTab.jsx */

.main {
    height: 45px;
    background-color: #E4EAFA;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 24px;
}

.deleteIcn {
    cursor: pointer;
    padding: 10px;
}

.formWrapper {
    padding: 20px;
}

.addNewSpocWrapper {
    padding: 12px;
}

.originatorListWrapper {
    height: 45px;
    background-color: #E4EAFA;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 24px;
}

/* BasicInformationTab */

.loadingWrapper{
    justify-content: center;
    align-items: center;
    padding: 80px;
    display: flex;
}


/* DocumentsTab.jsx */

.gridWrapper {
    height: 54px;
    background-color: #E4EAFA;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 24px;
}

.paddingStyle {
    padding: 10px;
}

.paddingTopStyle {
    padding-top: 10px;
}

/* KYCDocumentsTab.jsx */

.paddingLeftStyle {
    padding-left: 10px;
}

/* otherDetailsTab.jsx */

.headingAdjust {
    padding: 11px 0px;
}