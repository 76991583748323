.paddingStyle {
    padding: 24px;
}

.stackWrapper {
    height: 45px;
    background-color: #E4EAFA;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-top: 24px;
    padding-left: 24px;
}

.padding2Style {
    padding: 20px;
}

.pStyle {
    color: #AFB9C3;
    font-family: 400;
    font-size: 16px;
    
}