.button {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
