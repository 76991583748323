.shrinkContentWrapper {
    height: calc(100vh - 56px);
    width: calc(100% - 48px);
    overflow-y: scroll;
  }
  
  .expandContentWrapper {
    height: calc(100vh - 56px);
    width: calc(100% - 264px);
    overflow-y: scroll;
  }
  
  .flex {
    display: flex;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  
  }
  