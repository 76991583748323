.topDiv {
    padding: 15px;
    border-bottom: 1px solid #EBEBEB;
}

.pTag {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.childrenDiv{
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.btnTopDiv {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btnMidDiv {
    display: flex;
    align-items: center;
}

.btnLowerDiv {
    margin-right: 5px;
}

/* ModalV3 */

.midChildrenDiv{
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
}

.headDiv{
    padding: 15px;
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    justify-content: space-between;
}


/* View Modal Component */
/* Modal overlay to cover the entire screen */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Background blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it’s above other content */
  }
  /* Modal content styling */
  .modalContent {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    height: 80%;
    overflow: auto;
  }
