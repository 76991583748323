* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.sideDrawerWrapper {
  width: 100%;
  height: 100vh;
  /* filter: blur(5px); */
  /* background-color: black; */
  z-index: 101;
  /* opacity: 0.5; */

  position: fixed;
  top: 0;
  left: 0;
}

.sideDrawerWrapperClose {
  display: none;
}

.topSideDrawer {
  position: absolute;
  width: 70%;
  height: 100%;
  opacity: 0.5;
  background-color: black;
  filter: blur(5px);
}

.sideDrawerOpen {
  width: 30%;
  height: 100vh;
  background-color: white;

  position: absolute;
  right: 0;
  /* transition: transform 2s ease-in-out; */
  /* transform: translateX(30%); */
}

.sideDrawerClose {
  width: 0%;
  height: 100vh;
  background-color: white;

  position: absolute;
  right: 0;
  transition: transform 2s ease-in-out;
  /* transform: translateX(-30%); */
}

.closeIconWrapper {
  cursor: pointer;
}

.iconWrapper {
  position: absolute;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.TypographyWrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 16px;
}

.addSpocBtnWrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 16px;
}